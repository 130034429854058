import { BaseElement, html, css } from 'Components';
import App from 'App';
import { Fetcher, Lang } from 'Utils';

class LanguagesSelector extends BaseElement {
  static get styles() {
    return css`
      :host {
        display: block;
        color:gray;
        margin-right:10px;
      }

      sl-menu {
        border:0px;
      }

      sl-button::part(base) {
        font-size:25px;
        color:var(--sl-color-gray-200);
        width: 50px;
      }

      sl-menu-item::part(base) {
        line-height:25px;
      }

      sl-menu-item::part(label) {
        font-size:0.85em;
      }

      sl-menu-item:hover::part(base) {
        color:var(--sl-color-neutral-700);
      }

      sl-menu-item:focus-visible::part(base) {
        background:none;
        background-color:transparent;
        color:var(--sl-color-neutral-700);
      }

      sl-menu-item[aria-checked="true"]::part(base) {
        background-color:var(--sl-color-neutral-100);
      }

      .flag {
        font-size:15px;
      }

      m-icon {
        font-size: 33px;
      }

      sl-spinner {
      }

      .spinner {
        display:flex;
        align-items:center;
        justify-content:center;
        padding-top:10px;
      }

      @media print {
        :host {
          display:none;
        }
      }
    `
  }

  static get properties() {
    return {
      language: { type: String },
      languages: { type: Array },
      reload: { type: Boolean },
      novalue: { type: Boolean },
      hideCurrent: { type: Boolean },
      value: { type: String, reflect:true },
      lg: { type: String, reflect:true },
      lang: { type: String }
    }
  }

  constructor() {
    super();
    this.debug = false;
    this.language = localStorage.getItem(App.config.localKeys.language) || 'french';
    this.lg = App.config.lg;
    this.languages = null;
    this.reload = false;
    this.novalue = false;
  }

  connectedCallback() {
    super.connectedCallback();
    this.loadLanguages = this.loadLanguages.bind(this);  
    this.onLanguageChanged = this.onLanguageChanged.bind(this);

    window.addEventListener('language-updated', this.loadLanguages);
    window.addEventListener('language-changed', this.onLanguageChanged);
    this.loadLanguages();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('language-updated', this.loadLanguages);
    window.removeEventListener('language-changed', this.onLanguageChanged);
  }

  onLanguageChanged() {
    this.requestUpdate();
  }

  visibleCallback() {
    super.visibleCallback();
    // Why send 
    //this.sendEvent();
  }

  async loadLanguages() {
    const response = await Fetcher.get('public/system/languages');
    if (response?.data) {
      const languages = response.data;
      Lang.list = languages;
      if (this.hideCurrent) {
        this.languages = languages.filter(lang => lang.lang !== this.language);
      } else {
        this.languages = response.data;
      }
    }
  }

  sendEvent() {
    const customEvent = new CustomEvent('language-changed', {
      bubbles: true,
      composed: true,
      detail: { language: this.language },
    });

    this.dispatchEvent(customEvent);
  }

  updateLanguage(language, ev) {
    this._log.warn('updateLanguage', language);
    if (ev) ev.stopPropagation();
    this.language = language;
    this.lang = language;
    this.value = this.languages.filter(lang => lang.lang === language)[0].title.en;
    this.lg = this.languages.filter(lang => lang.lang === language)[0].lg;
    if (this.reload) {
      localStorage.setItem(App.config.localKeys.language, language);
      App.config.language = this.language;
      this.parentNode.parentNode.hide();
      this.sendEvent();
    }
  }

  render() {
    if (!this.languages) {
      return html`<div class="spinner"><sl-spinner style="font-size: 2rem;"></sl-spinner></div>`;
    }

    return html`
      <sl-menu size="small">
        ${this.languages.map(language => 
          html`
            <sl-menu-item 
              size="small" 
              class="menu_item" 
              type="checkbox" 
              ?checked="${!this.novalue 
                ? this.language === language.lang
                : this.lang === language.lang
              }"
              @click="${(e) => this.updateLanguage(language.lang, e)}"
            >
              ${language.title[App.config.lg] || language.title.fr}
              <span class="flag" slot="suffix">${language.flag}</span>
            </sl-menu-item>`
          )
        }
      </sl-menu>
    `;
  }
}

customElements.define('languages-selector', LanguagesSelector);

