import { BaseElement, html, css } from 'Components';

class Footer extends BaseElement {
  static get styles() {
    return [
      css`
        .container {
          display:grid;
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
          gap:20px;
          width:80vw;
        }

        .container > div {
          text-align:left;
        }

        .container img {
          width:100%;
          max-width:210px;
        }

        .clccs {
          font-size:1.2em;
        }

        a {
          color:white;
          text-decoration:none;
        }

        a:hover {
          text-decoration:underline;
        }

        .title {
          color:white;
          font-size:1.2em;
          margin-bottom:20px;
        }

        .content {
          color:#F0F0F0;
        }

        ul {
          list-style-type:none;
          padding:0px;
          margin:0px;
        }

        li {
          margin:5px;
          line-height:1.5em;
        }

        m-icon {
          float:left;
          color:var(--sl-color-primary-500);
          padding-top:2px;
          font-size:1.3em;
        }
      `
    ];
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            title: 'Center for the Fight Against Cybercrime',
            address: 'Address',
            phone: 'Phone',
            about: 'About us',
            contactus: 'Contact us',
            hire: 'SysDream is hiring',
            legal: 'Legal',
            privacy: 'Privacy policy',
            terms: 'Terms of use',
            legalnotice: 'Legal notice',
            certifications: 'Our certifications'

          }
        },
        french:{
          translation: {
            title: 'Centre de Lutte Contre la Cybercriminalite',
            address: 'Adresse',
            phone: 'Téléphone',
            about: 'À propos de nous',
            contactus: 'Contactez-nous',
            hire: 'SysDream recrute',
            legal: 'Juridique',
            privacy: 'Politique de confidentialité',
            terms: 'Conditions générales d\'utilisation',
            legalnotice: 'Mentions légales',
            certifications: 'Nos certifications',
          }
        }
      }
    ]
  }

  render() {
    return html`
      <app-footer>
        <br/>
        <hr-custom variant="gradient" width="80vw"></hr-custom>
        <br/>
        <div class="clccs">CLCCS - ${this._tl('title')}</div>
        <br/><br/><br/><br/>
        <div class="container">
          <div>
            <div class="title">${this._tl('contactus')}</div>
            <ul>
              <li><m-icon name="pin_drop"></m-icon>${this._tl('address')}: 53 rue Baudin, 92300 Levallois-Perret, France<br/><br/></li>
              <li><m-icon name="call"></m-icon>${this._tl('phone')}: +33(0)1 83 07 00 06</li>
            </ul>
          </div>
          <div>
            <div class="title">${this._tl('about')}</div>
            <ul>
              <li><a href="https://sysdream.com/propos/" target="sysdream">SysDream</a></li>
              <li><a href="https://sysdream.com/propos/blog/" target="sysdream">Blog</a></li>
              <li><a href="https://sysdream.com/propos/contactez-nous/">${this._tl('contactus')}</a></li>
              <li><a href="https://www.welcometothejungle.com/fr/companies/sysdream">${this._tl('hire')}</a></li>
            </ul>
          </div>
          <div>
            <div class="title">${this._tl('legal')}</div>
            <ul>
              <li><a href="https://sysdream.com/politique-confidentialite/">${this._tl('privacy')}</a></li>
              <li><a href="https://sysdream.com/conditions-generales-dutilisation/">${this._tl('terms')}</a></li>
              <li><a href="https://sysdream.com/mentions-legales/">${this._tl('legalnotice')}</a></li>
            </ul>
          </div>
          <div>
            <div class="title">${this._tl('certifications')}</div>
            <img src="/assets/img/qualiopi.png" alt="Qualiopi"/>
          </div>
        </div>
      </app-footer>
    `;
  }


}

customElements.define('app-footer-custom', Footer);
